import React from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 3rem 0;
  overflow-x: scroll;
	scroll-padding: 0 50%;
	scroll-snap-type: x mandatory;
`

const Element = styled.div`
  scroll-snap-align: center;
  margin-right: 2rem;

  &:last-child {
    margin-right: 0;
  }
`

const Title = styled.div`
  padding: 1.5rem 0;
  font-size: 1.2rem;
  font-weight: bold;
`

const ScrollableImages = ({ data }) => {

  return (
    <Container>
      {data.map((obj, index) => (
        <Element key={index}>
          <Img
            css={css`
              min-width: ${obj.image.aspectRatio * 300}px;
              height: 400px;
            `}
            alt={obj.title}
            fluid={obj.image}
          />
          <Title>{obj.title}</Title>
        </Element>
      ))}
    </Container>
  )
}

export default ScrollableImages
