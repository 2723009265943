import { graphql } from 'gatsby'
import React from 'react'

import StyledBackgroundHero from '../components/background-header'
import { CenterTextDiv } from '../components/common'
import Layout from '../components/layout'
import ScrollableImages from '../components/scrollableImages'
import SEO from '../components/seo'

const Online = ({ data }) => {
  const imageData = data.desktop.childImageSharp.fluid

  const image1 = data.image1.childImageSharp.fluid
  const image2 = data.image2.childImageSharp.fluid
  const image3 = data.image3.childImageSharp.fluid
  const image4 = data.image4.childImageSharp.fluid

  return (
    <Layout hero={<StyledBackgroundHero imageData={imageData} />}>
      <SEO title="Studio" />

      <CenterTextDiv>
        <h2>Ein Raum voller Energie &amp; Liebe</h2>

        <p>
          Unser Yogastudio erstreckt sich auf 160m<sup>2</sup>. Der große helle
          Raum bietet genügend Platz, um jedem Teilnehmer die Möglichkeit zu
          geben voll und ganz bei sich zu bleiben. Darum fasst ein Kurs bei uns
          auch nicht mehr als 15 Teilnehmer. In unserem Studio findest Du alles
          vor, was Du für den Yogaunterricht benötigst. Matten, Kissen, Klötze,
          Bänder, Decken, Stühle und Wasser werden für jeden Teilnehmer
          bereitgestellt. Du darfst natürlich auch Dein eigenes Equipment
          mitbringen. Auch Umkleidekabinen sind separat für Männer und Frauen
          vorhanden. Damit Du Dich individuell für die Yogastunde einrichten
          kannst, bitten wir Dich möglichst 15 Minuten vor Kursbeginn da zu
          sein. Wenn anschließend noch Zeit vorhanden ist, bietet sich auch die
          Möglichkeit auf eine Tasse Tee und ein nettes Gespräch zu bleiben, um
          einander näher kennenzulernen oder persönliche Fragen zu stellen.
        </p>
        <p>
          <strong>Wir freuen uns auf Dich!</strong>
        </p>
      </CenterTextDiv>

      <ScrollableImages
        data={[
          { image: image1 },
          { image: image2},
          { image: image3 },
          { image: image4 },
        ]}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    desktop: file(relativePath: { eq: "ebene-946@3x.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    image1: file(relativePath: { eq: "studio/DSC00489.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    image2: file(relativePath: { eq: "studio/DSC00493.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    image3: file(relativePath: { eq: "studio/DSC00496.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    image4: file(relativePath: { eq: "studio/DSC00507.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Online
